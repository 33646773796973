let eventGuid = 0
// let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
    // JAN 2024
    {
      id: createEventId(),
      start: new Date("2024-01-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-01-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต / E-filing
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-01-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-01-31T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `ยื่นรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 2ก, ภ.ง.ด. 3ก) สำหรับมาตรา 40 (4)(5)(6)(7)(8)
      Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 2A, Por.Ngor.Dor. 3A) for Article 40 (4)(5)(6)(7)(8)`,
      color: '#748DA6'
    },
  //FEBRUARY 2024
  {
    id: createEventId(),
    start: new Date("2024-02-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-02-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    ยื่นรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 2ก, ภ.ง.ด. 3ก) สำหรับมาตรา 40 (4)(5)(6)(7)(8)
    Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 2A, Por.Ngor.Dor. 3A) for Article 40 (4)(5)(6)(7)(8)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-02-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต 
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-02-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-02-29T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `ยื่นนรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 1ก และ ภ.ง.ด. 1ก พิเศษ) สำหรับมาตรา 40 (1)(2)
    Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 1A, Por.Ngor.Dor. 1A Special) for Article 40 (1)(2)`,
    color: '#748DA6'
  },
  //MARCH
  {
    id: createEventId(),
    start: new Date("2024-03-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-03-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    ยื่นรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 1ก ) สำหรับมาตรา 40 (1)(2)
    Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 1A) for Article 40 (1)(2)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-03-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: ` (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-03-25T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //APRIL
  {
    id: createEventId(),
    start: new Date("2024-04-01T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "PIT",
    description: `ยื่นแบบแสดงรายการภาษีเงินได้บุคคลธรรมดาประจำปี พ.ศ. 2565 (ภ.ง.ด. 90, 91, 95)
    Annual Personal Income Tax Return for 2022 (Por.Ngor.Dor. 90, 91, 95)\n
    ยื่นแบบแสดงรายการภาษีป้าย (ภ.ป. 1)
    Signboard Tax Return (Phor.Por. 1)`,
    color:'#92B4EC'
  },
  {
    id: createEventId(),
    start: new Date("2024-04-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)
    ยื่นแบบแสดงรายการภาษีเงินได้บุคคลธรรมดาประจำปี พ.ศ. 2565 (ภ.ง.ด. 90, 91, 95)
    Annual Personal Income Tax Return for 2022 (Por.Ngor.Dor. 90, 91, 95)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-04-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-04-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //MAY
  {
    id: createEventId(),
    start: new Date("2024-05-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นeส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54,Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-05-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-05-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-05-29T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "CIT",
    description: `(สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้นและสิ้นสุด 1 มกราคม - 31 ธันวาคม)
    ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคลประจำปี พ.ศ. 2565 (ภ.ง.ด. 50, 52, 55)
    แบบรายงานประจำปี (Disclosure Form) สำหรับบริษัทหรือห้างหุ้นส่วนนิติบุคคลที่มีความสัมพันธ์กันตาม ม. 71 ทวิ
    (for the juristic person having the fiscal year starting from January 1-ending December 31 )
    Annual Corporate Income Tax Return for 2022 (Por.Ngor.Dor. 50, 52, 55)
    Disclusure Form for a company or juristic partnership that are related, under Section 71 Bis`,
    color:'#9A86A4'
  },
  //JUNE
  {
    id: createEventId(),
    start: new Date("2024-06-06T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "CIT",
    description: `(สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้นและสิ้นสุด 1 มกราคม - 31 ธันวาคม)
    ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคลประจำปี พ.ศ. 2565 (ภ.ง.ด. 50, 52, 55)
    แบบรายงานประจำปี (Disclosure Form) สำหรับบริษัทหรือห้างหุ้นส่วนนิติบุคคลที่มีความสัมพันธ์กันตาม ม. 71 ทวิ
    (for the juristic person having the fiscal year starting from January 1-ending December 31 )
    Annual Corporate Income Tax Return for 2022 (Por.Ngor.Dor. 50, 52, 55)
    Disclusure Form for a company or juristic partnership that are related, under Section 71 Bis`,
    color:'#9A86A4'
  },
  {
    id: createEventId(),
    start: new Date("2024-06-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-06-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-06-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //JULY
  {
    id: createEventId(),
    start: new Date("2024-07-01T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "LBT",
    description: `ยื่นรายการภาษีที่ดินและสิ่งปลูกสร้าง ประจำปี พ.ศ. 2567
    Payment of Land and Building Tax of 2024`,
    color:'#B1BCE6'
  },
  {
    id: createEventId(),
    start: new Date("2024-07-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-07-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-07-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //AUGUST
  {
    id: createEventId(),
    start: new Date("2024-08-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-08-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-08-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //SEPTEMBER
  {
    id: createEventId(),
    start: new Date("2024-09-02T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "CIT",
    description: `ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคล (ภ.ง.ด. 51) สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้น 1 มกราคม สิ้นสุด 31 ธันวาคม
    Semi-Annual Corporate Income Tax Return (Por.Ngor.Dor. 51) for the juristic person having the fiscal year starting from January 1 - ending December 31`,
    color:'#9A86A4'
  },
  {
    id: createEventId(),
    start: new Date("2024-09-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคล (ภ.ง.ด. 51) สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้น 1 มกราคม สิ้นสุด 31 ธันวาคม
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)
    Semi-Annual Corporate Income Tax Return (Por.Ngor.Dor. 51) for the juristic person having the fiscal year starting from January 1-ending December 31`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-09-16T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-09-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-09-30T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "PIT",
    description: `ยื่นรายการภาษีเงินได้บุคคลธรรมดาครึ่งปี (ภ.ง.ด. 94) สำหรับมาตรา 40 (5)(6)(7)(8)
    Semi-Annual Personal Income Tax Return (Por.Ngor.Dor. 94) for Article 40(5)(6)(7)(8)`,
    color:'#92B4EC'
  },
  // OCTOBER
  {
    id: createEventId(),
    start: new Date("2024-10-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-10-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    ยื่นรายการภาษีเงินได้บุคคลธรรมดาครึ่งปี (ภ.ง.ด. 94) สำหรับมาตรา 40 (5)(6)(7)(8)
    Semi-Annual Personal Income Tax Return (Por.Ngor.Dor. 94) for Article 40(5)(6)(7)(8)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-10-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-10-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  // NOVEMBER
  {
    id: createEventId(),
    start: new Date("2024-11-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-11-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-11-25T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  // DECEMBER
  {
    id: createEventId(),
    start: new Date("2024-12-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-12-16T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-12-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  }
]

export function createEventId() {
  return String(eventGuid++)
}


export const SetdataAll2025 = [
  //January
  {
  maindate:"2025-01-07",
  datefirst:"January 7, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"",
  
},
{
      maindate:"2025-01-15",
      datefirst:"January 15, 2025 (e-Filing)",
      datesecond:"January 15, 2025",
      detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
      detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
      maindate:"2025-01-23",
      datefirst:"January 23, 2025 (e-Filing)",
      datesecond:"",
      detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
      detailssecond:"",
},
{
  maindate:"2025-01-31",
  datefirst:"January 31, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return for 2024 (P.N.D. 2a, P.N.D. 3a) for Section 40(4)(5)(6)(7)(8)",
  detailssecond:"",
},
  //February
{
  maindate:"2025-02-07",
  datefirst:"February 7, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"",
},
{
  maindate:"2025-02-10",
  datefirst:"February 10, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return for 2024 (P.N.D. 2a, P.N.D. 3a) for Section 40(4)(5)(6)(7)(8)",
  detailssecond:"",
},
{
  maindate:"2025-02-17",
  datefirst:"February 17, 2025 (e-Filing)",
  datesecond:"February 17, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-02-24",
  datefirst:"February 24, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
{
  maindate:"2025-02-28",
  datefirst:"February 28, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return for 2024 (P.N.D. 1a) for Section 40(1)(2)",
  detailssecond:"",
},
 //March
 {
  maindate:"2025-03-07",
  datefirst:"March 7, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"",
},
{
  maindate:"2025-03-10",
  datefirst:"March 10, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return for 2024 (P.N.D. 1a) for Section 40(1)(2)",
  detailssecond:"",
},
{
  maindate:"2025-03-17",
  datefirst:"March 17, 2025 (e-Filing)",
  datesecond:"March 17, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-03-24",
  datefirst:"March 24, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
{
  maindate:"2025-03-31",
  datefirst:"March 31, 2025",
  datesecond:"",
  detailsfirst:"• Personal Income Tax Return for 2024 (P.N.D. 90, 91, 95)\n • Signboard Tax Return (PP. 1)",
  detailssecond:"",
},
//April
{
  maindate:"2025-04-08",
  datefirst:"April 8, 2025",
  datesecond:"April 8, 2025 (e-Filing)",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• Personal Income Tax Return for 2024 (P.N.D. 90, 91, 95)",
},
{
  maindate:"2025-04-17",
  datefirst:"April 17, 2025 (e-Filing)",
  datesecond:"April 17, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-04-23",
  datefirst:"April 23, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
//May
{
  maindate:"2025-05-07",
  datefirst:"May 7, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"",
},
{
  maindate:"2025-05-15",
  datefirst:"May 15, 2025 (e-Filing)",
  datesecond:"May 15, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-05-23",
  datefirst:"May 23, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
{
  maindate:"2025-05-30",
  datefirst:"May 30, 2025 (for a juristic person with a fiscal year starting and ending from January 1 to December 31)",
  datesecond:"",
  detailsfirst:"• Corporate Income Tax Return for 2024 (P.N.D. 50, 52, 55)\n • Disclosure Form for companies or juristic partnerships that are related, under Section 71 Bis",
  detailssecond:"",
},
//June
{
  maindate:"2025-06-09",
  datefirst:"June 9, 2025",
  datesecond:"June 9, 2025 (for a juristic person with a fiscal year starting and ending from January 1 to December 31) (e-Filing)",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• Corporate Income Tax Return for 2024 (P.N.D. 50, 52, 55)\n • Disclosure Form for companies or juristic partnerships that are related, under Section 71 Bis",
},
{
  maindate:"2025-06-16",
  datefirst:"June 16, 2025 (e-Filing)",
  datesecond:"June 16, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-06-23",
  datefirst:"June 23, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
{
  maindate:"2025-06-30",
  datefirst:"June 30, 2025",
  datesecond:"",
  detailsfirst:"• Payment for Land and Building Tax for the Year 2025\n *According to the Notification of the Ministry of Interior regarding the Extension of the Deadline for Compliance with the Land and Buildings Tax Act B.E. 2019 for the year 2025, dated October 30, 2024 (extended from the usual April deadline of every tax year)",
  detailssecond:"",
},
//July
{
  maindate:"2025-07-07",
  datefirst:"July 07, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"",
},
{
  maindate:"2025-07-15",
  datefirst:"July 15, 2025 (e-Filing)",
  datesecond:"July 15, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-07-23",
  datefirst:"July 23, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
//August
{
  maindate:"2025-08-07",
  datefirst:"August 07, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"",
},
{
  maindate:"2025-08-15",
  datefirst:"August 15, 2025 (e-Filing)",
  datesecond:"August 15, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-08-23",
  datefirst:"August 23, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
//September
{
  maindate:"2025-09-01",
  datefirst:"September 1, 2025 (for a juristic person with a fiscal year starting and ending from January 1 to December 31)",
  datesecond:"",
  detailsfirst:"• Corporate Income Tax Return (P.N.D. 51)",
  detailssecond:"",
},
{
  maindate:"2025-09-08",
  datefirst:"September 8, 2025 (for a juristic person with a fiscal year starting and ending from January 1 to December 31) (e-Filing)",
  datesecond:"September 8, 2025",
  detailsfirst:"• Corporate Income Tax Return (P.N.D. 51)",
  detailssecond:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
},
{
  maindate:"2025-09-15",
  datefirst:"September 15, 2025 (e-Filing)",
  datesecond:"September 15, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-09-23",
  datefirst:"September 23, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
{
  maindate:"2025-09-30",
  datefirst:"September 30, 2025",
  datesecond:"",
  detailsfirst:"• Half-Year Personal Income Tax Return (P.N.D. 94) for Section 40 (5)(6)(7)(8)",
  detailssecond:"",
},
//October
{
  maindate:"2025-10-07",
  datefirst:"October 7, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"",
},
{
  maindate:"2025-10-08",
  datefirst:"October 8, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• Half-Year Personal Income Tax Return (P.N.D. 94) for Section 40 (5)(6)(7)(8)",
  detailssecond:"",
},
{
  maindate:"2025-10-15",
  datefirst:"October 15, 2025 (e-Filing)",
  datesecond:"October 15, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-10-24",
  datefirst:"October 24, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
//November
{
  maindate:"2025-11-07",
  datefirst:"November 7, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"",
},
{
  maindate:"2025-11-17",
  datefirst:"November 17, 2025 (e-Filing)",
  datesecond:"November 17, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-11-24",
  datefirst:"November 24, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
 //December
 {
  maindate:"2025-12-08",
  datefirst:"December 8, 2025",
  datesecond:"",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"",
},
{
  maindate:"2025-12-15",
  datefirst:"December 15, 2025 (e-Filing)",
  datesecond:"December 15, 2025",
  detailsfirst:"• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)",
  detailssecond:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
},
{
  maindate:"2025-12-23",
  datefirst:"December 23, 2025 (e-Filing)",
  datesecond:"",
  detailsfirst:"• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)",
  detailssecond:"",
},
];
