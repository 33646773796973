import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS,SetdataAll2025 } from './event-utils'
import { Modal } from "react-bootstrap";
import './TaxationCalendar.css';
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
// import image222 from '../../Assets/DCM_LOGO.PNG';
import RD_Tax_Deadline from './RD_Tax_Deadline.png';
import BMA_Tax_Deadline from './BMA_Tax_Deadline.png';
import RD from './rd.webp';

function TaxationCalendar() {
    const { t,i18n } = useTranslation();

    function createEventId() {
      return String(eventGuid++)
    }


    
    let eventGuid = 0
    function renderEventContent(eventInfo) {
      const date = eventInfo.event.start;
      const dateFormatgetyear =  date.toLocaleDateString('en-CA').substring(0,4);
      if(dateFormatgetyear == "2024"){
        return (
          <>
            <i>{eventInfo.event.title}</i>
          </>
        )
      }else{
      return (
        <>
        {/*  className='justify-content-md-center text-center' */}
          {/* <Col  xs={12} md={12} className='dcmlogo-container textcadendar' >
          <div className='title-carender-center'>
          {t(`${eventInfo.event.title}`)}
          </div>
            </Col> */}
         <Col  xs={12} md={12} className='dcmlogo-container text-left text-align-left title-carender-left'>
         <img className='dcmLogo' src={(`${BMA_Tax_Deadline}`)}  alt="dcmlogo" />
         <img className='dcmLogord' src={(`${RD}`)}  alt="dcmlogo" />
         
         {/* {
          eventInfo.event.id == 2 || eventInfo.event.id == 3?<img className='dcmLogo' src={(`${BMA_Tax_Deadline}`)}  alt="dcmlogo" />:""
         } */}
          </Col>
          {/* <Col  xs={12} md={12} className='dcmlogo-container text-left text-align-left title-carender-left'>
         <img className='dcmLogo' src={(`${RD}`)}  alt="dcmlogo" />
          </Col> */}
        </>
      )
    }
    }

    const [state2024, setState2024] = React.useState({
      modal: false,
      currentEvents: [],
      dateENG: '',
      dateTH: '',
      title: '',
      description: '',
      eventBackgroundColor:''
    });


    const [state, setState] = React.useState({
      modal: false,
      currentEvents: [],
      dateENG: '',
      dateTH: '',
      title: '',
      description: '',
      eventBackgroundColor:'',
      maindate:'',
      datefirst:'',
      datesecond:'',
      detailsfirst:'',
      detailssecond:'',

    });


    function handleEvents(events){
      setState({
        currentEvents: events
      })
    }
    function handleEventClick(clickInfo)  {
      // console.log(clickInfo)
      clickInfo.jsEvent.preventDefault();
    
      if(clickInfo.event.extendedProps.description){
          const date = clickInfo.event.start;
          const title = clickInfo.event.title;
          const description = clickInfo.event.extendedProps.description;
          const backgroundColor =  clickInfo.event.backgroundColor;
          // en-GB
          const dateFormat = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
          const dateFormatTH = date.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' });
          const dateFormatyymmdd = date.toLocaleDateString('en-CA');
          const dateFormatgetyear =  date.toLocaleDateString('en-CA').substring(0,4);
          let getdatasearch;
          console.log(dateFormatgetyear)
          if(dateFormatgetyear == "2024"){

            toggle2024(dateFormat, dateFormatTH , title, description);
          }else{
          if(dateFormatgetyear == "2025"){
            getdatasearch = SetdataAll2025.filter(x=>{ return dateFormatyymmdd == x.maindate});
          }else if(dateFormatgetyear == "2026"){
            // getdatasearch = SetdataAll2026.filter(x=>{ return dateFormatyymmdd == x.maindate});
          }

          toggle(dateFormat, dateFormatTH , title, description,backgroundColor,getdatasearch[0].maindate,getdatasearch[0].datefirst,getdatasearch[0].datesecond,getdatasearch[0].detailsfirst,getdatasearch[0].detailssecond);
        }
         
      }
    }

    function toggle2024(dateFormat, dateFormatTH, title, description, eventBackgroundColor){
      console.log(dateFormat)
      setState2024({ 
          modal: !state2024.modal,
          dateENG: dateFormat,
          dateTH: dateFormatTH,
          title,
          description,
          eventBackgroundColor
      });
    };

    function toggle(dateFormat, dateFormatTH, title, description, eventBackgroundColor,maindate,datefirst,datesecond,detailsfirst,detailssecond){
      setState({ 
          modal: !state.modal,
          dateENG: dateFormat,
          dateTH: dateFormatTH,
          title,
          description,
          eventBackgroundColor,
          maindate,
          datefirst,
          datesecond,
          detailsfirst,
          detailssecond,
      });
    };

  return (
    <div className="tax-calendar-container" id="calendar" style={{height:'85vh'}}>
        <Row className='justify-content-md-center text-center'>
        <Col xs={12} md={12} >
    <h4>{t("Stay on Track with Thailand's 2025 Tax Deadlines")}</h4>
    {t("Keep your finances compliant with key dates and deadlines for the year.")}
    </Col>
    </Row>
    <Row className='justify-content-md-center text-center mt-4'>
    <div className="demo-app-calendar">
      {/* height="auto"
    contentHeight="auto" */}
  <FullCalendar
    height="auto"
    contentHeight="auto"
    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
    headerToolbar={{
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }}
    initialView='dayGridMonth'
    selectable={true}
    selectMirror={true}
    showNonCurrentDates={false}  
    fixedWeekCount={false}
    handleWindowResize={true}
    initialEvents={[
      {
        id: createEventId(),
        start: new Date("2024-01-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
        title: "WHT",
        description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
        (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
        color: '#748DA6'
      },
      {
        id: createEventId(),
        start: new Date("2024-01-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
        title: "WHT",
        description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
        (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
        ยื่นแบบทางอินเทอร์เน็ต / E-filing
        (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
        (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
        color: '#748DA6'
      },
      {
        id: createEventId(),
        start: new Date("2024-01-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
        title: "VAT / SBT",
        description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
        (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
        color:'#68A7AD'
      },
      {
        id: createEventId(),
        start: new Date("2024-01-31T12:00:00Z").toISOString().replace(/T.*$/, '') ,
        title: "WHT",
        description: `ยื่นรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 2ก, ภ.ง.ด. 3ก) สำหรับมาตรา 40 (4)(5)(6)(7)(8)
        Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 2A, Por.Ngor.Dor. 3A) for Article 40 (4)(5)(6)(7)(8)`,
        color: '#748DA6'
      },
    //FEBRUARY 2024
    {
      id: createEventId(),
      start: new Date("2024-02-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-02-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `ยื่นแบบทางอินเทอร์เน็ต
      ยื่นรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 2ก, ภ.ง.ด. 3ก) สำหรับมาตรา 40 (4)(5)(6)(7)(8)
      Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 2A, Por.Ngor.Dor. 3A) for Article 40 (4)(5)(6)(7)(8)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-02-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต 
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-02-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-02-29T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `ยื่นนรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 1ก และ ภ.ง.ด. 1ก พิเศษ) สำหรับมาตรา 40 (1)(2)
      Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 1A, Por.Ngor.Dor. 1A Special) for Article 40 (1)(2)`,
      color: '#748DA6'
    },
    //MARCH
    {
      id: createEventId(),
      start: new Date("2024-03-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-03-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `ยื่นแบบทางอินเทอร์เน็ต
      ยื่นรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 1ก ) สำหรับมาตรา 40 (1)(2)
      Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 1A) for Article 40 (1)(2)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-03-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: ` (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-03-25T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    //APRIL
    {
      id: createEventId(),
      start: new Date("2024-04-01T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "PIT",
      description: `ยื่นแบบแสดงรายการภาษีเงินได้บุคคลธรรมดาประจำปี พ.ศ. 2565 (ภ.ง.ด. 90, 91, 95)
      Annual Personal Income Tax Return for 2022 (Por.Ngor.Dor. 90, 91, 95)\n
      ยื่นแบบแสดงรายการภาษีป้าย (ภ.ป. 1)
      Signboard Tax Return (Phor.Por. 1)`,
      color:'#92B4EC'
    },
    {
      id: createEventId(),
      start: new Date("2024-04-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)
      ยื่นแบบแสดงรายการภาษีเงินได้บุคคลธรรมดาประจำปี พ.ศ. 2565 (ภ.ง.ด. 90, 91, 95)
      Annual Personal Income Tax Return for 2022 (Por.Ngor.Dor. 90, 91, 95)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-04-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-04-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    //MAY
    {
      id: createEventId(),
      start: new Date("2024-05-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นeส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54,Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-05-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-05-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-05-29T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "CIT",
      description: `(สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้นและสิ้นสุด 1 มกราคม - 31 ธันวาคม)
      ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคลประจำปี พ.ศ. 2565 (ภ.ง.ด. 50, 52, 55)
      แบบรายงานประจำปี (Disclosure Form) สำหรับบริษัทหรือห้างหุ้นส่วนนิติบุคคลที่มีความสัมพันธ์กันตาม ม. 71 ทวิ
      (for the juristic person having the fiscal year starting from January 1-ending December 31 )
      Annual Corporate Income Tax Return for 2022 (Por.Ngor.Dor. 50, 52, 55)
      Disclusure Form for a company or juristic partnership that are related, under Section 71 Bis`,
      color:'#9A86A4'
    },
    //JUNE
    {
      id: createEventId(),
      start: new Date("2024-06-06T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "CIT",
      description: `(สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้นและสิ้นสุด 1 มกราคม - 31 ธันวาคม)
      ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคลประจำปี พ.ศ. 2565 (ภ.ง.ด. 50, 52, 55)
      แบบรายงานประจำปี (Disclosure Form) สำหรับบริษัทหรือห้างหุ้นส่วนนิติบุคคลที่มีความสัมพันธ์กันตาม ม. 71 ทวิ
      (for the juristic person having the fiscal year starting from January 1-ending December 31 )
      Annual Corporate Income Tax Return for 2022 (Por.Ngor.Dor. 50, 52, 55)
      Disclusure Form for a company or juristic partnership that are related, under Section 71 Bis`,
      color:'#9A86A4'
    },
    {
      id: createEventId(),
      start: new Date("2024-06-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-06-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-06-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    //JULY
    {
      id: createEventId(),
      start: new Date("2024-07-01T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "LBT",
      description: `ยื่นรายการภาษีที่ดินและสิ่งปลูกสร้าง ประจำปี พ.ศ. 2567
      Payment of Land and Building Tax of 2024`,
      color:'#B1BCE6'
    },
    {
      id: createEventId(),
      start: new Date("2024-07-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-07-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-07-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    //AUGUST
    {
      id: createEventId(),
      start: new Date("2024-08-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-08-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-08-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    //SEPTEMBER
    {
      id: createEventId(),
      start: new Date("2024-09-02T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "CIT",
      description: `ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคล (ภ.ง.ด. 51) สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้น 1 มกราคม สิ้นสุด 31 ธันวาคม
      Semi-Annual Corporate Income Tax Return (Por.Ngor.Dor. 51) for the juristic person having the fiscal year starting from January 1 - ending December 31`,
      color:'#9A86A4'
    },
    {
      id: createEventId(),
      start: new Date("2024-09-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคล (ภ.ง.ด. 51) สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้น 1 มกราคม สิ้นสุด 31 ธันวาคม
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)
      Semi-Annual Corporate Income Tax Return (Por.Ngor.Dor. 51) for the juristic person having the fiscal year starting from January 1-ending December 31`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-09-16T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-09-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-09-30T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "PIT",
      description: `ยื่นรายการภาษีเงินได้บุคคลธรรมดาครึ่งปี (ภ.ง.ด. 94) สำหรับมาตรา 40 (5)(6)(7)(8)
      Semi-Annual Personal Income Tax Return (Por.Ngor.Dor. 94) for Article 40(5)(6)(7)(8)`,
      color:'#92B4EC'
    },
    // OCTOBER
    {
      id: createEventId(),
      start: new Date("2024-10-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-10-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `ยื่นแบบทางอินเทอร์เน็ต
      ยื่นรายการภาษีเงินได้บุคคลธรรมดาครึ่งปี (ภ.ง.ด. 94) สำหรับมาตรา 40 (5)(6)(7)(8)
      Semi-Annual Personal Income Tax Return (Por.Ngor.Dor. 94) for Article 40(5)(6)(7)(8)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-10-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-10-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    // NOVEMBER
    {
      id: createEventId(),
      start: new Date("2024-11-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-11-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-11-25T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    // DECEMBER
    {
      id: createEventId(),
      start: new Date("2024-12-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-12-16T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-12-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
      {
      id: 1,
      start: new Date("2025-01-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-01-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-01-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-01-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-01-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-01-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.T. 40`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-01-31T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-01-31T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 2a`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-02-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-02-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-02-10T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-02-10T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 2a`,
      description: `• Withholding Income Tax Return for 2024 (P.N.D. 2a, P.N.D. 3a) for Section 40(4)(5)(6)(7)(8)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-02-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-02-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    }
    ,
    {
      id: 2,
      start: new Date("2025-02-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-02-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-02-28T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-02-28T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 2a`,
      description: `• Withholding Income Tax Return for 2024 (P.N.D. 1a) for Section 40(1)(2)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //March
    {
      id: 2,
      start: new Date("2025-03-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-03-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-03-10T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-03-10T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 2a`,
      description: `• Withholding Income Tax Return for 2024 (P.N.D. 2a, P.N.D. 3a) for Section 40(4)(5)(6)(7)(8)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-03-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-03-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    }
    ,
    {
      id: 2,
      start: new Date("2025-03-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-03-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-03-31T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-03-31T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 1a`,
      description: `• Withholding Income Tax Return for 2024 (P.N.D. 1a) for Section 40(1)(2)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //April
    {
      id: 2,
      start: new Date("2025-04-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-04-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-04-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-04-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-04-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-04-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //May
    {
      id: 2,
      start: new Date("2025-05-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-05-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-05-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-05-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-05-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-05-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-05-30T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-05-30T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 50`,
      description: `• Corporate Income Tax Return for 2024 (P.N.D. 50, 52, 55)\n • Disclosure Form for companies or juristic partnerships that are related, under Section 71 Bis`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //June
    {
      id: 2,
      start: new Date("2025-06-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-06-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-06-16T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-06-16T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-06-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-06-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-06-30T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-06-30T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `*`,
      description: `• Payment for Land and Building Tax for the Year 2025`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //July
    {
      id: 2,
      start: new Date("2025-07-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-07-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-07-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-07-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-07-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-07-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.T. 40`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //August
    {
      id: 2,
      start: new Date("2025-08-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-08-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-08-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-08-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-08-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-08-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.T. 40`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //September
    {
      id: 2,
      start: new Date("2025-09-01T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-09-01T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 51`,
      description: `• Corporate Income Tax Return (P.N.D. 51)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-09-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-09-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 51`,
      description: `• Corporate Income Tax Return (P.N.D. 51)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-09-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-09-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-09-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-09-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-09-30T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-09-30T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 94`,
      description: `• Half-Year Personal Income Tax Return (P.N.D. 94) for Section 40 (5)(6)(7)(8)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //October
    {
      id: 2,
      start: new Date("2025-10-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-10-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-10-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-10-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.N.D. 94`,
      description: `• Half-Year Personal Income Tax Return (P.N.D. 94) for Section 40 (5)(6)(7)(8)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-10-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-10-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-10-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-10-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //November
    {
      id: 2,
      start: new Date("2025-11-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-11-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-11-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-11-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-11-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-11-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    //December
    {
      id: 2,
      start: new Date("2025-12-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-12-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-12-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-12-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 36`,
      description: `• Withholding Income Tax Return (P.N.D. 1, 2, 3, 53, 54) / VAT Remittance Return (P.P. 36)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
    {
      id: 2,
      start: new Date("2025-12-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      stop: new Date("2025-12-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      url:RD_Tax_Deadline,
      title: `P.P. 30`,
      description: `• VAT Return (P.P. 30) / Specific Business Tax Return (P.T. 40)`,
      color: '#ffffff',
      textColor:'#313757',
      // color: '#748DA6'
    },
  ]} 
    eventContent={renderEventContent} // custom render function
    eventClick={handleEventClick}
    eventsSet={handleEvents} // called after events are initialized/added/changed/removed
  />
  </div>
    </Row>
    <Modal
          show={state2024.modal}
          onHide={toggle2024}
          className="event-modal"
          centered
          size="xl"
        >
          <Modal.Header closeButton className="event-header">
            {state2024.dateTH} / {state2024.dateENG}
          </Modal.Header>
          <Modal.Body className="event-body">
            <h5>{state2024.title}</h5>
            <p className="event-modal-description">{state2024.description}</p>
          </Modal.Body>
        </Modal>

<Modal
  show={state.modal}
  onHide={toggle}
  className="event-modal"
  centered
  size="xl"
>
  <Modal.Header closeButton className="event-header">
    {i18n.language == 'th' ?state.dateTH:state.dateENG} 
  </Modal.Header>
  <Modal.Body className="event-body">
    <p >{t(state.datefirst)}</p>
    {/* <h5>{t(state.title)}</h5> */}
    <p className="event-modal-description ms-2">{t(state.detailsfirst)}</p>
    <p>{t(state.datesecond)}</p>
    <p className="event-modal-description ms-2">{t(state.detailssecond)}</p>
  </Modal.Body>
</Modal>
</div>
  )
};
export default TaxationCalendar